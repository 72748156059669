// Placeholder to allow defining custom styles that override everything else.
// (Use `_sass/minima/custom-variables.scss` to override variable defaults)

.home-title {
    display: block;
    margin: auto;
    text-align: center;
    margin-top: 30px;

    h1 {
        margin: 0 30px;
        font-weight: 500;
        font-size: 28px;
    }

    .typewriter {
        color: $dm-brand-color;
        background-color: #444;
        padding: 0 5px;
        font-size: 18px;
        overflow: hidden;
        max-width: 265px;
        font-weight: 500;
        // padding-bottom: 20px;
        /* Ensures the content is not revealed until the animation */
        border-right: 12px solid #bbb;
        /* The typwriter cursor */
        white-space: nowrap;
        /* Keeps the content on a single line */
        margin: 0 auto;
        /* Gives that scrolling effect as the typing happens */
        letter-spacing: .15em;
        /* Adjust as needed */
        animation:
            typing 3.5s steps(40, end),
            blink-caret .75s step-end infinite;
    }

    /* The typing effect */
    @keyframes typing {
        from {
            width: 0
        }

        to {
            width: 100%
        }
    }

    /* The typewriter cursor effect */
    @keyframes blink-caret {

        from,
        to {
            border-color: transparent
        }

        50% {
            border-color: #bbb;
        }
    }
}

.home-h2 {
    a {
        color: $dm-secondary-brand-color;

        &:hover {
            filter: drop-shadow(0 0 1px $dm-secondary-brand-color);
            text-decoration: none;
        }
    }
}

.custom-post {
    ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
    }

    h3 {
        margin: 0;

        a {
            margin-top: 0;
            color: $dm-brand-color;
            font-size: 20px;
        }
    }

    li {
        margin-bottom: $spacing-unit;
        padding-bottom: 18px;
        border-bottom: $brand-color 1px dashed;

        img {
            width: 30px;
        }

        p {
            // color: #fff;

            a {
                margin-top: 0;
                color: #bbbbbb;
                text-decoration: underline;
                text-underline-offset: 5px;
                opacity: 0.6;
            }
        }
    }

    .title {
        margin-bottom: 8px;
        padding: 0 8px;
        border-left: 6px;
        border-top: 0;
        border-right: 0;
        border-bottom: 0;
        border-style: solid;
        border-color: $dm-brand-color;
        display: flex;

        .heading {
            display: flex;
            flex-direction: column;
        }
    }
}

.bytes {
    h3 {
        color: $dm-brand-color;
        font-size: 20px;

        a {
            cursor: pointer;

            &:hover {
                text-decoration: none;
                filter: drop-shadow(0 0 2px $dm-brand-color);
            }
        }
    }

    img {
        border: 1px solid #333;
    }

    .see-more {
        text-align: right;
    }

    hr {
        border: 1px dashed $dm-brand-color;
    }
}

.video-container {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    margin: 30px 0;

    .video {
        // min-width: 47%;
        // height: 100px;
        // margin: 15px;
        background: gray;
    }
}

.profile {
    width: 150px;
    margin: 0 auto;
    display: block;

    filter: grayscale(1);
}

.profile-home {
    width: 80px;
    filter: grayscale(1);
}

.home-posts {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    gap: 10px;

    h3 {
        height: 100px;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .post {
        img {
            width: 80px;
        }
    }

    time {
        color: #333;
        font-size: 13px;
    }
}

.home-bytes {
    margin-top: -20px;
    display: flex;
    gap: 12px;

    h3 {
        height: 55px;
        overflow: hidden;
        font-size: 20px;
        text-overflow: ellipsis;
        color: $dm-brand-color;
    }

    .byte {
        img {
            border: 2px dashed $dm-brand-color;
            cursor: pointer;

            &:hover {
                filter: drop-shadow(0 0 6px $dm-brand-color);
            }
        }
    }

    time {
        color: #333;
        font-size: 13px;
    }
}

.home-videos {
    display: flex;
    flex-wrap: wrap;
    gap: 18px;
    margin: 30px auto;

    .video {
        // min-width: 47%;
        // height: 100px;
        // margin: 15px;
        border: 1px dashed #bbb;
        background: rgba(128, 128, 128, 0);
    }
}

.certificates {
    display: flex;
    justify-items: center;
    margin: auto;
    width: 310px;

    img {
        width: 80px;
        margin: auto;
        display: block;
    }
}