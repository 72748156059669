@charset "utf-8";

// Default color scheme settings
// These are overridden in classic.css and dark.scss

$color-scheme-auto: true !default;
$color-scheme-dark: false !default;


// Light mode
// ----------

$lm-brand-color: #828282 !default;
$lm-brand-color-light: lighten($lm-brand-color, 40%) !default;
$lm-brand-color-dark: darken($lm-brand-color, 25%) !default;

$lm-site-title-color: $lm-brand-color-dark !default;

$lm-text-color: #111111 !default;
$lm-background-color: #fdfdfd !default;
$lm-code-background-color: #eeeeff !default;

$lm-link-base-color: #2a7ae2 !default;
$lm-link-visited-color: darken($lm-link-base-color, 15%) !default;
$lm-link-hover-color: $lm-text-color !default;

$lm-border-color-01: $lm-brand-color-light !default;
$lm-border-color-02: lighten($lm-brand-color, 35%) !default;
$lm-border-color-03: $lm-brand-color-dark !default;

$lm-table-text-color: lighten($lm-text-color, 18%) !default;
$lm-table-zebra-color: lighten($lm-brand-color, 46%) !default;
$lm-table-header-bg-color: lighten($lm-brand-color, 43%) !default;
$lm-table-header-border: lighten($lm-brand-color, 37%) !default;
$lm-table-border-color: $lm-border-color-01 !default;


// Syntax highlighting styles should be adjusted appropriately for every "skin"
// ----------------------------------------------------------------------------

@mixin lm-highlight {
  .highlight {
    .c {
      color: #998;
      font-style: italic
    }

    // Comment
    .err {
      color: #a61717;
      background-color: #e3d2d2
    }

    // Error
    .k {
      font-weight: bold
    }

    // Keyword
    .o {
      font-weight: bold
    }

    // Operator
    .cm {
      color: #998;
      font-style: italic
    }

    // Comment.Multiline
    .cp {
      color: #999;
      font-weight: bold
    }

    // Comment.Preproc
    .c1 {
      color: #998;
      font-style: italic
    }

    // Comment.Single
    .cs {
      color: #999;
      font-weight: bold;
      font-style: italic
    }

    // Comment.Special
    .gd {
      color: #000;
      background-color: #fdd
    }

    // Generic.Deleted
    .gd .x {
      color: #000;
      background-color: #faa
    }

    // Generic.Deleted.Specific
    .ge {
      font-style: italic
    }

    // Generic.Emph
    .gr {
      color: #a00
    }

    // Generic.Error
    .gh {
      color: #999
    }

    // Generic.Heading
    .gi {
      color: #000;
      background-color: #dfd
    }

    // Generic.Inserted
    .gi .x {
      color: #000;
      background-color: #afa
    }

    // Generic.Inserted.Specific
    .go {
      color: #888
    }

    // Generic.Output
    .gp {
      color: #555
    }

    // Generic.Prompt
    .gs {
      font-weight: bold
    }

    // Generic.Strong
    .gu {
      color: #aaa
    }

    // Generic.Subheading
    .gt {
      color: #a00
    }

    // Generic.Traceback
    .kc {
      font-weight: bold
    }

    // Keyword.Constant
    .kd {
      font-weight: bold
    }

    // Keyword.Declaration
    .kp {
      font-weight: bold
    }

    // Keyword.Pseudo
    .kr {
      font-weight: bold
    }

    // Keyword.Reserved
    .kt {
      color: #458;
      font-weight: bold
    }

    // Keyword.Type
    .m {
      color: #099
    }

    // Literal.Number
    .s {
      color: #d14
    }

    // Literal.String
    .na {
      color: #008080
    }

    // Name.Attribute
    .nb {
      color: #0086B3
    }

    // Name.Builtin
    .nc {
      color: #458;
      font-weight: bold
    }

    // Name.Class
    .no {
      color: #008080
    }

    // Name.Constant
    .ni {
      color: #800080
    }

    // Name.Entity
    .ne {
      color: #900;
      font-weight: bold
    }

    // Name.Exception
    .nf {
      color: #900;
      font-weight: bold
    }

    // Name.Function
    .nn {
      color: #555
    }

    // Name.Namespace
    .nt {
      color: #000080
    }

    // Name.Tag
    .nv {
      color: #008080
    }

    // Name.Variable
    .ow {
      font-weight: bold
    }

    // Operator.Word
    .w {
      color: #bbb
    }

    // Text.Whitespace
    .mf {
      color: #099
    }

    // Literal.Number.Float
    .mh {
      color: #099
    }

    // Literal.Number.Hex
    .mi {
      color: #099
    }

    // Literal.Number.Integer
    .mo {
      color: #099
    }

    // Literal.Number.Oct
    .sb {
      color: #d14
    }

    // Literal.String.Backtick
    .sc {
      color: #d14
    }

    // Literal.String.Char
    .sd {
      color: #d14
    }

    // Literal.String.Doc
    .s2 {
      color: #d14
    }

    // Literal.String.Double
    .se {
      color: #d14
    }

    // Literal.String.Escape
    .sh {
      color: #d14
    }

    // Literal.String.Heredoc
    .si {
      color: #d14
    }

    // Literal.String.Interpol
    .sx {
      color: #d14
    }

    // Literal.String.Other
    .sr {
      color: #009926
    }

    // Literal.String.Regex
    .s1 {
      color: #d14
    }

    // Literal.String.Single
    .ss {
      color: #990073
    }

    // Literal.String.Symbol
    .bp {
      color: #999
    }

    // Name.Builtin.Pseudo
    .vc {
      color: #008080
    }

    // Name.Variable.Class
    .vg {
      color: #008080
    }

    // Name.Variable.Global
    .vi {
      color: #008080
    }

    // Name.Variable.Instance
    .il {
      color: #099
    }

    // Literal.Number.Integer.Long
  }
}


// Dark mode
// ---------

$dm-brand-color: #25cc45 !default;
$dm-brand-color-light: lighten($dm-brand-color, 5%) !default;
$dm-brand-color-dark: darken($dm-brand-color, 35%) !default;

$dm-site-title-color: $dm-brand-color-light !default;

$dm-text-color: #bbbbbb !default;
$dm-background-color: #444 !default;
$dm-code-background-color: #212121 !default;
$dm-dark-background-color: #333 !default;
$dm-secondary-brand-color: #E3651D !default;

$dm-link-base-color: #79b8ff !default;
$dm-link-visited-color: $dm-link-base-color !default;
$dm-link-hover-color: $dm-text-color !default;

$dm-border-color-01: $dm-brand-color-dark !default;
$dm-border-color-02: $dm-brand-color-light !default;
$dm-border-color-03: $dm-brand-color !default;

$dm-table-text-color: $dm-text-color !default;
$dm-table-zebra-color: lighten($dm-background-color, 4%) !default;
$dm-table-header-bg-color: lighten($dm-background-color, 10%) !default;
$dm-table-header-border: lighten($dm-background-color, 21%) !default;
$dm-table-border-color: $dm-border-color-01 !default;


// Syntax highlighting styles should be adjusted appropriately for every "skin"
// List of tokens: https://github.com/rouge-ruby/rouge/wiki/List-of-tokens
// Some colors come from Material Theme Darker:
// https://github.com/material-theme/vsc-material-theme/blob/master/scripts/generator/settings/specific/darker-hc.ts
// https://github.com/material-theme/vsc-material-theme/blob/master/scripts/generator/color-set.ts
// ----------------------------------------------------------------------------

@mixin dm-highlight {
  .highlight {
    .c {
      color: #545454;
      font-style: italic
    }

    // Comment
    .err {
      color: #f07178;
      background-color: #e3d2d2
    }

    // Error
    .k {
      color: #89DDFF;
      font-weight: bold
    }

    // Keyword
    .o {
      font-weight: bold
    }

    // Operator
    .cm {
      color: #545454;
      font-style: italic
    }

    // Comment.Multiline
    .cp {
      color: #545454;
      font-weight: bold
    }

    // Comment.Preproc
    .c1 {
      color: #545454;
      font-style: italic
    }

    // Comment.Single
    .cs {
      color: #545454;
      font-weight: bold;
      font-style: italic
    }

    // Comment.Special
    .gd {
      color: #000;
      background-color: #fdd
    }

    // Generic.Deleted
    .gd .x {
      color: #000;
      background-color: #faa
    }

    // Generic.Deleted.Specific
    .ge {
      font-style: italic
    }

    // Generic.Emph
    .gr {
      color: #f07178
    }

    // Generic.Error
    .gh {
      color: #999
    }

    // Generic.Heading
    .gi {
      color: #000;
      background-color: #dfd
    }

    // Generic.Inserted
    .gi .x {
      color: #000;
      background-color: #afa
    }

    // Generic.Inserted.Specific
    .go {
      color: #888
    }

    // Generic.Output
    .gp {
      color: #555
    }

    // Generic.Prompt
    .gs {
      font-weight: bold
    }

    // Generic.Strong
    .gu {
      color: #aaa
    }

    // Generic.Subheading
    .gt {
      color: #f07178
    }

    // Generic.Traceback
    .kc {
      font-weight: bold
    }

    // Keyword.Constant
    .kd {
      font-weight: bold
    }

    // Keyword.Declaration
    .kp {
      font-weight: bold
    }

    // Keyword.Pseudo
    .kr {
      font-weight: bold
    }

    // Keyword.Reserved
    .kt {
      color: #FFCB6B;
      font-weight: bold
    }

    // Keyword.Type
    .m {
      color: #F78C6C
    }

    // Literal.Number
    .s {
      color: #C3E88D
    }

    // Literal.String
    .na {
      color: #008080
    }

    // Name.Attribute
    .nb {
      color: #EEFFFF
    }

    // Name.Builtin
    .nc {
      color: #FFCB6B;
      font-weight: bold
    }

    // Name.Class
    .no {
      color: #008080
    }

    // Name.Constant
    .ni {
      color: #800080
    }

    // Name.Entity
    .ne {
      color: #900;
      font-weight: bold
    }

    // Name.Exception
    .nf {
      color: #82AAFF;
      font-weight: bold
    }

    // Name.Function
    .nn {
      color: #555
    }

    // Name.Namespace
    .nt {
      color: #FFCB6B
    }

    // Name.Tag
    .nv {
      color: #EEFFFF
    }

    // Name.Variable
    .ow {
      font-weight: bold
    }

    // Operator.Word
    .w {
      color: #EEFFFF
    }

    // Text.Whitespace
    .mf {
      color: #F78C6C
    }

    // Literal.Number.Float
    .mh {
      color: #F78C6C
    }

    // Literal.Number.Hex
    .mi {
      color: #F78C6C
    }

    // Literal.Number.Integer
    .mo {
      color: #F78C6C
    }

    // Literal.Number.Oct
    .sb {
      color: #C3E88D
    }

    // Literal.String.Backtick
    .sc {
      color: #C3E88D
    }

    // Literal.String.Char
    .sd {
      color: #C3E88D
    }

    // Literal.String.Doc
    .s2 {
      color: #C3E88D
    }

    // Literal.String.Double
    .se {
      color: #EEFFFF
    }

    // Literal.String.Escape
    .sh {
      color: #C3E88D
    }

    // Literal.String.Heredoc
    .si {
      color: #C3E88D
    }

    // Literal.String.Interpol
    .sx {
      color: #C3E88D
    }

    // Literal.String.Other
    .sr {
      color: #C3E88D
    }

    // Literal.String.Regex
    .s1 {
      color: #C3E88D
    }

    // Literal.String.Single
    .ss {
      color: #C3E88D
    }

    // Literal.String.Symbol
    .bp {
      color: #999
    }

    // Name.Builtin.Pseudo
    .vc {
      color: #FFCB6B
    }

    // Name.Variable.Class
    .vg {
      color: #EEFFFF
    }

    // Name.Variable.Global
    .vi {
      color: #EEFFFF
    }

    // Name.Variable.Instance
    .il {
      color: #F78C6C
    }

    // Literal.Number.Integer.Long
  }
}


// Mode selection
// --------------


// Classic skin (always light mode)
// Assign outside of the if construct to establish global variable scope

$brand-color: $lm-brand-color;
$brand-color-light: $lm-brand-color-light;
$brand-color-dark: $lm-brand-color-dark;

$site-title-color: $lm-site-title-color;

$text-color: $lm-text-color;
$background-color: $lm-background-color;
$code-background-color: $lm-code-background-color;

$link-base-color: $lm-link-base-color;
$link-visited-color: $lm-link-visited-color;
$link-hover-color: $lm-link-hover-color;

$border-color-01: $lm-border-color-01;
$border-color-02: $lm-border-color-02;
$border-color-03: $lm-border-color-03;

$table-text-color: $lm-table-text-color;
$table-zebra-color: $lm-table-zebra-color;
$table-header-bg-color: $lm-table-header-bg-color;
$table-header-border: $lm-table-header-border;
$table-border-color: $lm-table-border-color;


@if $color-scheme-auto {

  // Auto mode

  :root {
    --minima-brand-color: #{$lm-brand-color};
    --minima-brand-color-light: #{$lm-brand-color-light};
    --minima-brand-color-dark: #{$lm-brand-color-dark};

    --minima-site-title-color: #{$lm-site-title-color};

    --minima-text-color: #{$lm-text-color};
    --minima-background-color: #{$lm-background-color};
    --minima-code-background-color: #{$lm-code-background-color};

    --minima-link-base-color: #{$lm-link-base-color};
    --minima-link-visited-color: #{$lm-link-visited-color};
    --minima-link-hover-color: #{$lm-link-hover-color};

    --minima-border-color-01: #{$lm-border-color-01};
    --minima-border-color-02: #{$lm-border-color-02};
    --minima-border-color-03: #{$lm-border-color-03};

    --minima-table-text-color: #{$lm-table-text-color};
    --minima-table-zebra-color: #{$lm-table-zebra-color};
    --minima-table-header-bg-color: #{$lm-table-header-bg-color};
    --minima-table-header-border: #{$lm-table-header-border};
    --minima-table-border-color: #{$lm-table-border-color};
  }

  @include lm-highlight;

  @media (prefers-color-scheme: dark) {
    :root {
      --minima-brand-color: #{$dm-brand-color};
      --minima-brand-color-light: #{$dm-brand-color-light};
      --minima-brand-color-dark: #{$dm-brand-color-dark};

      --minima-site-title-color: #{$dm-site-title-color};

      --minima-text-color: #{$dm-text-color};
      --minima-background-color: #{$dm-background-color};
      --minima-code-background-color: #{$dm-code-background-color};

      --minima-link-base-color: #{$dm-link-base-color};
      --minima-link-visited-color: #{$dm-link-visited-color};
      --minima-link-hover-color: #{$dm-link-hover-color};

      --minima-border-color-01: #{$dm-border-color-01};
      --minima-border-color-02: #{$dm-border-color-02};
      --minima-border-color-03: #{$dm-border-color-03};

      --minima-table-text-color: #{$dm-table-text-color};
      --minima-table-zebra-color: #{$dm-table-zebra-color};
      --minima-table-header-bg-color: #{$dm-table-header-bg-color};
      --minima-table-header-border: #{$dm-table-header-border};
      --minima-table-border-color: #{$dm-table-border-color};
    }

    @include dm-highlight;
  }

  $brand-color: var(--minima-brand-color);
  $brand-color-light: var(--minima-brand-color-light);
  $brand-color-dark: var(--minima-brand-color-dark);

  $site-title-color: var(--minima-site-title-color);

  $text-color: var(--minima-text-color);
  $background-color: var(--minima-background-color);
  $code-background-color: var(--minima-code-background-color);

  $link-base-color: var(--minima-link-base-color);
  $link-visited-color: var(--minima-link-visited-color);
  $link-hover-color: var(--minima-link-hover-color);

  $border-color-01: var(--minima-border-color-01);
  $border-color-02: var(--minima-border-color-02);
  $border-color-03: var(--minima-border-color-03);

  $table-text-color: var(--minima-table-text-color);
  $table-zebra-color: var(--minima-table-zebra-color);
  $table-header-bg-color: var(--minima-table-header-bg-color);
  $table-header-border: var(--minima-table-header-border);
  $table-border-color: var(--minima-table-border-color);


}

@else if $color-scheme-dark {

  // Dark skin (always dark mode)

  $brand-color: $dm-brand-color;
  $brand-color-light: $dm-brand-color-light;
  $brand-color-dark: $dm-brand-color-dark;

  $site-title-color: $dm-site-title-color;

  $text-color: $dm-text-color;
  $background-color: $dm-background-color;
  $code-background-color: $dm-code-background-color;

  $link-base-color: $dm-link-base-color;
  $link-visited-color: $dm-link-visited-color;
  $link-hover-color: $dm-link-hover-color;

  $border-color-01: $dm-border-color-01;
  $border-color-02: $dm-border-color-02;
  $border-color-03: $dm-border-color-03;

  $table-text-color: $dm-table-text-color;
  $table-zebra-color: $dm-table-zebra-color;
  $table-header-bg-color: $dm-table-header-bg-color;
  $table-header-border: $dm-table-header-border;
  $table-border-color: $dm-table-border-color;

  @include dm-highlight;


}

@else {

  // Classic skin syntax highlighting
  @include lm-highlight;

}